import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Card from "./Components/Card";
const axios = require("axios");

function App() {
  const [posts, setPosts] = useState([]);
  const loadPosts = async () => {
    const result = await axios(
      "https://falling-dawn-0b9a.deinjulies.workers.dev/posts"
    );
    setPosts(result?.data || []);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { content, username, title } = e.currentTarget.elements;
    const post = {
      content: content.value,
      title: title.value,
      username: username.value,
    };

    fetch("https://falling-dawn-0b9a.deinjulies.workers.dev/posts", {
      method: "POST",
      body: JSON.stringify(post),
    })
      .then((response) => response.json())
      .then((response) => {
        const form = e.target;
        form.reset();
        setPosts(response || []);
      });
  };
  useEffect(() => {
    loadPosts();
  }, []);
  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        marginLeft: 60,
        marginRight: 60,
      }}
    >
      <Paper
        elevation={7}
        square
        style={{
          width: "80%",
        }}
      >
        <Paper elevation={3} square style={{ width: "100%", height: "15vh" }}>
          <Box marginLeft={5}>
            <Typography variant="h6">Add New Post</Typography>
            <form onSubmit={onSubmit}>
              <Box
                display="flex"
                maxWidth="80%"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField name="username" required label="username" />
                <TextField name="title" required label="title" />
                <TextField name="content" required label="content" />
                <Box>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Paper>
        <Box style={{ margin: 10 }}>
          <Grid container spacing={3}>
            {posts?.map((post, i) => (
              <Grid key={i} item md={4}>
                <Card post={post} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default App;
